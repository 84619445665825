.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

#root {
  height: 100vh;
}

.Td {
  width: 20%;
}

.Input {
  background-color: #cebb9f;
}

.Tr {
  width: 100%;
}

.enjoy-css2 {
  min-width: 410px;
  max-width: 450px;
  border-style: solid;
  border-width: 10px 10px 10px 10px;
  border-image: linear-gradient(#f6b73c, #4d9f0c) round;

}

.enjoy-css {
  min-width: 410px;
  max-width: 450px;
  border-style: solid;
  border-width: 10px 10px 10px 10px;
  -moz-border-image: url(https://www.pidl.org/site-assets/images/line.png/@@images/image.png) round;
  -webkit-border-image: url(https://www.pidl.org/site-assets/images/line.png/@@images/image.png) round;
  -o-border-image: url(https://www.pidl.org/site-assets/images/line.png/@@images/image.png) round;
  border-image: url(https://www.pidl.org/site-assets/images/line.png/@@images/image.png) round;

}

.width-css {
  min-width: 210px;
  max-width: 250px;
  word-break: break-all;
}

.App-link {
  color: #61dafb;
}

.btn-xsm {
  font-size: 0.5rem;
  min-width: 20px;
  width: 20px;
  padding-left: 5px;
}

.btn-xsm-remove {
  font-size: 0.9rem;
  min-width: 20px;
  width: 30px;
  min-height: 20px;
  height: 30px;
  padding-left: 7px;
  padding-top: 2px;
}

.form-control-xsm {
  min-height: 20px;
  height: 29px;
  padding-left: 5px;
}

.Img {
  border: 1px solid #f3ca00;
  border-radius: 4px;
}

.ImgLght {
  border: 1px solid #000b44;
  border-radius: 4px;
}

.divwithoutscroll::-webkit-scrollbar {
  width: 0;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}
body::-webkit-scrollbar {
  width: 0;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}

#dropdown-basic-button {
  width: 80px;
}

.dropdown-menu {
  background:transparent;
  min-width: 80px;
  padding: 0;
}

.Text {
  color: #f3ca00;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.table {
  --bs-table-bg: transparent;
}